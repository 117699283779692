/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import { Typography } from '@mui/material'
import Head from 'next/head'
import { ReactNode, useState } from 'react'
import Layout from '@/components/Layout'
import styles from '@/styles/Login.module.css'
import supabase from '@/lib/supabaseClient'
import Container from '@mui/material/Container'
import { Box } from '@mui/material'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import CircularProgress from '@mui/material/CircularProgress'
import { currentSession } from '@/lib/localAuth'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import { useMediaQuery } from '@mui/material'
import GoogleLoginButton from '@/components/buttons/GoogleLoginButton'
import FacebookLoginButton from '@/components/buttons/FacebookLoginButton'
import * as Sentry from '@sentry/browser'

export default function Login() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [incorrect, setIncorrect] = useState(false)
  const [somethingWrong, setSomethingWrong] = useState(false)
  const [emailNotConfirmed, setEmailNotConfirmed] = useState(false)
  const [fillOutEmail, setFillOutEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [forgotActionInProgress, setForgotActionInProgress] = useState(false)
  const router = useRouter()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)

  if (!!currentSession()) {
    router.push('/')
    return
  }

  const manageLoginError = (error: string) => {
    if (error == 'Invalid login credentials') {
      setIncorrect(true)
    } else if (error == 'Email not confirmed') {
      setEmailNotConfirmed(true)
    } else {
      setSomethingWrong(true)
    }
  }

  const providerLogin = async (provider: 'google' | 'facebook') => {
    try {
      setLoading(true)
      setIncorrect(false)
      setSomethingWrong(false)
      setEmailNotConfirmed(false)
      const { error } = await supabase.auth.signIn(
        { provider },
        {
          redirectTo: process.env.NEXT_PUBLIC_BASE_URL,
        }
      )
      if (error) throw error
    } catch (error: any) {
      manageLoginError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const login = async (event: any) => {
    event.preventDefault()

    try {
      setLoading(true)
      setIncorrect(false)
      setSomethingWrong(false)
      setEmailNotConfirmed(false)
      const { error } = await supabase.auth.signIn(
        {
          email: event.target.email.value,
          password: event.target.password.value,
        },
        {
          redirectTo: process.env.NEXT_PUBLIC_BASE_URL,
        }
      )
      if (error) throw error
      Sentry.setUser({ email: event.target.email.value })
    } catch (error: any) {
      console.log(error)
      manageLoginError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const forgottenPassword = async () => {
    if (email == '') {
      setFillOutEmail(true)
      return
    }
    setEmailSent(false)
    setSomethingWrong(false)
    setEmailNotConfirmed(false)
    setForgotActionInProgress(true)

    const { error } = await supabase.auth.api.resetPasswordForEmail(email)

    setForgotActionInProgress(false)

    if (!!error) setSomethingWrong(true)
    else setEmailSent(true)
  }

  return (
    <div className={styles.container}>
      <Head>
        <title>{t('pages.login.title')}</title>
      </Head>

      <Container component="main" maxWidth="xs">
        <Typography variant="h2">{t('pages.login.title')}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 1
            }}
            component="form" onSubmit={login}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('pages.login.email')}
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              onChange={(e: any) => {
                setEmail(e.target.value)
                setFillOutEmail(false)
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('pages.login.password')}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('pages.login.sign_in')}
            </Button>

            {somethingWrong && <Typography color="red">{t('pages.login.something_wrong')}</Typography>}
            {emailNotConfirmed && <Typography color="red">{t('pages.login.email_not_confirmed')}</Typography>}
            {incorrect && <Typography color="red">{t('pages.login.incorrect_credentials')}</Typography>}
            {loading
              ? <CircularProgress />
              : <><GoogleLoginButton onClick={(e: any) => {
                e.preventDefault()
                providerLogin('google')
              }} />
                <FacebookLoginButton onClick={(e: any) => {
                  e.preventDefault()
                  providerLogin('facebook')
                }} />
              </>}

            <Box sx={{ mt: 4, mb: 2 }}>
              {forgotActionInProgress ? (
                <Typography>
                  <CircularProgress />
                  {t('pages.login.checking')}
                </Typography>
              ) : (
                <Link href="#" onClick={forgottenPassword}>
                  <Typography>{t('pages.login.forgot_password')}</Typography>
                </Link>
              )}
            {fillOutEmail && <Typography color="red">{t('pages.login.fill_out_email_for_reset')}</Typography>}
            {emailSent && <Typography color="green">{t('pages.login.reset_password_email_sent')}</Typography>}
            </Box>
            <Typography>
              {t('pages.login.sign_up_message')}{' '}
              <a href="/sign_up" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                {t('pages.login.sign_up')}
              </a>
            </Typography>

            { isMobile &&
              <Button
                sx={{ mt: 3 }}
                variant='outlined'
                onClick={(e: any) => {
                  e.preventDefault()
                  router.push('/help')
                }}
              >
                <Typography>{t('common.help')}</Typography>
              </Button>
            }
          </Box>
        </Box>
      </Container>
    </div>
  )
}

Login.getLayout = function getLayout(page: ReactNode) {
  return <Layout>{page}</Layout>
}
