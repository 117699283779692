export const currentSession: any = () => {
  if (typeof window === 'undefined') return null

  const value = localStorage.getItem('supabase.auth.token')
  if (!value) return null

  const json = JSON.parse(value)
  const expiration = json.expiresAt
  if (expiration * 1000 < Date.now()) return null

  return json.currentSession
}

export const currentUser: any = () => currentSession()?.user

export const currentUserEmail = () => currentUser()?.email
