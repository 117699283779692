import { useTranslation } from 'react-i18next'
import styles from './FacebookLoginButton.module.css'

type facebookLoginButtonProps = {
    onClick: (_e: any) => void // eslint-disable-line no-unused-vars
}

export default function FacebookLoginButton({ onClick }: facebookLoginButtonProps) {
    const { t } = useTranslation()

    return (
        <div onClick={(e) => onClick(e)} className={styles.facebook_btn}>
            <div className={styles.facebook_icon_wrapper}>
                <img
                    alt="Facebook sign-in"
                    className={styles.facebook_icon}
                    src="/images/logoFacebookLogin.svg" />
            </div>
            <p className={styles.btn_text}><b>{t('pages.login.sign_in_with_facebook')}</b></p>
        </div>
    )
}