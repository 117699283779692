import { useTranslation } from 'react-i18next'
import styles from './GoogleLoginButton.module.css'

type googleLoginButtonProps = {
    onClick: (e: any) => void // eslint-disable-line no-unused-vars
}

export default function GoogleLoginButton({ onClick }: googleLoginButtonProps) {
    const { t } = useTranslation()

    return (
        <div onClick={(e) => onClick(e)} className={styles.google_btn}>
            <div className={styles.google_icon_wrapper}>
                <img
                    alt="Google sign-in"
                    className={styles.google_icon}
                    src="/images/logoGoogleLogin.svg" />
            </div>
            <p className={styles.btn_text}><b>{t('pages.login.sign_in_with_google')}</b></p>
        </div>
    )
}